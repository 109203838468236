// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

import { withCSS } from "framer"
import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import { useEffect } from "react"

const css = `
    .supporta-campaign-overview .supporta-campaign-card {
        border-radius: 0px;
        box-shadow: none;
        border: 1px solid #f3f3f3;
        font-family: "Museo Sans Rounded 500", "Museo Sans Rounded 500 Placeholder", sans-serif;
    }
    .supporta-campaign-overview .supporta-project-title {
        display: none;
    }
    .supporta-campaign-overview .supporta-campaign-card-content h3 {
        font-size: 1.35em;
        color: #181717;
    }
    .supporta-campaign-overview .supporta-campaign-card:hover {
        box-shadow: none;
    }
    .supporta-campaign-overview .supporta-search-input {
        border: 1px solid rgb(234 230 228);
        font-family: "Museo Sans Rounded 500", "Museo Sans Rounded 500 Placeholder", sans-serif;
        border-radius: 0px;
    }
    .supporta-campaign-overview .supporta-show-more-button, .supporta-show-less-button {
        border-radius: 0px;
        font-family: "Museo Sans Rounded 500", "Museo Sans Rounded 500 Placeholder", sans-serif;
        font-size: 14px;
        box-shadow: none;
    }
    .supporta-campaign-overview .supporta-show-less-button {
        background: #fafafa;
        border-radius: 0px;
    }
    .supporta-campaign-overview .supporta-campaign-card:hover {
        opacity: 0.8;
    }
    .supporta-campaign-overview .supporta-campaigns-overview {
        gap: 2rem !important;
    }
    .supporta-campaign-funds-raised, .supporta-fundraising-target {
    font-size: 14px;
}
`

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Acties_individueel_collecte(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    useEffect(() => {
        const script = document.createElement("script")

        script.src = "https://cdn.supporta.cc/embed.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div>
            <div
                className="supporta-campaign-overview"
                data-charity="bm1m"
                data-projects="ia4yp2"
                data-per-page="12"
                data-columns="4"
                data-include-teams="false"
                data-inactivity-days="30"
            ></div>

            <style>{css}</style>
        </div>
    )
}
